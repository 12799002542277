<template>
  <ClientLayout title="Connexion">
    <div class="flex flex-col md:flex-row">
      <!-- Partie gauche : Formulaire de connexion -->
      <div class="w-full md:w-1/2 p-8">
        <div class="max-w-md mx-auto">
          <div class="flex justify-between">
            <div class="flex justify-start gap-6">
              <h1 class="text-2xl text-black font-bold pb-7">Se connecter</h1>
            </div>
          </div>

          <div v-if="teamInvitation" class="mt-6">
            <!-- Contenu de l'invitation d'équipe inchangé -->
            <p class="mb-1 font-semibold">Vous avez été invité par <span class="font-bold text-green-100">{{ teamInvitation }}</span> à rejoindre son équipe.</p>
            <a :href="route('register')" class="underline text-green-100 cursor-pointer hover:text-green-200 text-lg font-semibold text-right">&raquo; Créer votre compte</a>
          </div>
          <div v-else>
            <span class="text-sm">Vous n'avez pas de compte ? </span>
            <a :href="route('register')" class="underline text-green-100 cursor-pointer hover:text-green-200 text-sm">Créer un compte</a>
          </div>

          <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
          </div>

          <form @submit.prevent="submit" class="mt-8">
            <!-- Email -->
            <div class="mb-4">
              <InputLabel for="email" value="Email"/>
              <TextInput
                  id="email"
                  v-model="form.email"
                  type="email"
                  class="mt-1 block w-full"
                  required
                  autofocus
                  autocomplete="username"
              />
              <InputError class="mt-2" :message="form.errors.email"/>
            </div>

            <!-- Password -->
            <FormInput class="mb-4" no-border>
              <template #label>Mot de passe</template>
              <template #input="inputProps">
                <TextInput id="password" :type="passwordConfirmationManager.inputType" v-model="form.password"
                           v-bind="inputProps" required autocomplete="current-password"/>
              </template>
              <template #after-input>
                <!-- Icône pour afficher/masquer le mot de passe -->
              </template>
              <template #error>
                <span class="text-red-500">{{ form.errors.password }}</span>
              </template>
            </FormInput>

            <div class="flex items-center justify-between mb-6">
              <label class="flex items-center">
                <Checkbox v-model:checked="form.remember" name="remember"/>
                <span class="ml-2 text-sm text-gray-600">Se souvenir de moi</span>
              </label>
              <Link v-if="canResetPassword" :href="route('password.request')"
                    class="text-sm text-green-100 hover:text-green-200">
                Mot de passe oublié ?
              </Link>
            </div>

            <Button variant="primary" class="w-full" type="submit" :disabled="form.processing"
                    :class="{ 'opacity-25': form.processing }">
              Connexion
            </Button>
          </form>
        </div>
      </div>

      <!-- Partie droite : Image -->
      <div class="hidden md:block w-1/2">
        <div class="flex items-center justify-center">
          <LoginGirl class="object-cover h-full w-5/6" />
        </div>
      </div>
    </div>
  </ClientLayout>
</template>

<script setup>
import ClientLayout from '@/Layouts/ClientLayout.vue';
import Button from '@/Components/Button.vue';
import FormInput from '@/Components/Client/Inputs/FormInput.vue';
import TextInput from '@/Components/Client/Inputs/TextInput.vue';
import {Link, useForm} from '@inertiajs/vue3';
import {computed, onMounted, reactive, ref} from 'vue';
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import Checkbox from "@/Components/Checkbox.vue";
import LoginGirl from "@/Components/Client/Icons/LoginGirl.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  canResetPassword: Boolean,
  status: String,
  teamInvitation: String,
});


/**
 * Current Password Manager
 */
const currentPasswordManager = reactive({
  isShown: ref(false),
  inputType: computed(() => {
    return currentPasswordManager.isShown ? 'text' : 'password';
  }),
  show: () => {
    currentPasswordManager.isShown = true;
  },
  hide: () => {
    currentPasswordManager.isShown = false;
  },
  toggle: (value = currentPasswordManager.isShown) => {
    if (value) {
      currentPasswordManager.hide();
    } else {
      currentPasswordManager.show();
    }
  },
});

/**
 * Password Manager
 */
const passwordManager = reactive({
  isShown: ref(false),
  inputType: computed(() => {
    return passwordManager.isShown ? 'text' : 'password';
  }),
  show: () => {
    passwordManager.isShown = true;
  },
  hide: () => {
    passwordManager.isShown = false;
  },
  toggle: (value = passwordManager.isShown) => {
    if (value) {
      passwordManager.hide();
    } else {
      passwordManager.show();
    }
  },
});

/**
 * Password Confirmation Manager
 */
const passwordConfirmationManager = reactive({
  isShown: ref(false),
  inputType: computed(() => {
    return passwordConfirmationManager.isShown ? 'text' : 'password';
  }),
  show: () => {
    passwordConfirmationManager.isShown = true;
  },
  hide: () => {
    passwordConfirmationManager.isShown = false;
  },
  toggle: (value = passwordConfirmationManager.isShown) => {
    if (value) {
      passwordConfirmationManager.hide();
    } else {
      passwordConfirmationManager.show();
    }
  },
});

onMounted(() => {
  if (props.teamInvitation) {
    goToRegister();
  }
});

const goToRegister = () => {
  window.location.href = route('register');
};

const form = useForm({
  email: '',
  password: '',
  remember: false,
});

const submit = () => {
  form.transform(data => ({
    ...data,
    remember: form.remember ? 'on' : '',
  })).post(route('login'), {
    onFinish: () => form.reset('password'),
  });
};

</script>