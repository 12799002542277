<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g id="freepik-login">
      <path
          d="M238.4,341.53H45.31a5.72,5.72,0,0,1-5.71-5.71V64.39a5.72,5.72,0,0,1,5.71-5.66H238.4a5.71,5.71,0,0,1,5.7,5.71h0V335.82A5.71,5.71,0,0,1,238.4,341.53ZM45.31,58.93a5.47,5.47,0,0,0-5.46,5.46V335.82a5.47,5.47,0,0,0,5.46,5.46H238.4a5.46,5.46,0,0,0,5.45-5.46V64.39a5.46,5.46,0,0,0-5.45-5.46Z"
          style="fill:#e6e6e6"></path>
      <path
          d="M454.7,341.53H261.6a5.71,5.71,0,0,1-5.7-5.71V64.39a5.71,5.71,0,0,1,5.7-5.66H454.7a5.71,5.71,0,0,1,5.7,5.66V335.82A5.71,5.71,0,0,1,454.7,341.53ZM261.6,58.93a5.46,5.46,0,0,0-5.45,5.46V335.82a5.46,5.46,0,0,0,5.45,5.46H454.7a5.48,5.48,0,0,0,5.47-5.46V64.39a5.48,5.48,0,0,0-5.47-5.46Z"
          style="fill:#e6e6e6"></path>
      <path
          d="M207.75,197.46,206.38,315c-4.84,39.11-74.17,70.16-74.17,70.16S62.87,354.14,58,315L56.59,197.46l74.1-24.39Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M198.08,204.9l-1.19,105.54c-4.22,34.11-64.69,61.19-64.69,61.19s-60.47-27.08-64.68-61.19L66.25,204.9l64.63-21.27Z"
          style="fill:#e0e0e0"></path>
      <path
          d="M193.84,211.56l-1.12,96.89c-4,32-60.65,57.36-60.65,57.36s-56.69-25.39-60.64-57.36l-1.19-96.89,60.59-19.94Z"
          style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;stroke-dasharray:0,33"></path>
      <rect x="306.94" y="157.79" width="133.38" height="234.04" style="fill:#f5f5f5"></rect>
      <rect x="316.8" y="166.99" width="113.85" height="224.84" style="fill:#e0e0e0"></rect>
      <path
          d="M320,392.83a1.43,1.43,0,0,1-.58-.12,1.39,1.39,0,0,1-.48-.32,1.33,1.33,0,0,1-.33-.49,1.56,1.56,0,0,1-.11-.57,1.5,1.5,0,0,1,1.79-1.47l.28.08.26.14a2.11,2.11,0,0,1,.23.19,1,1,0,0,1,.18.23,1.08,1.08,0,0,1,.14.26,1.31,1.31,0,0,1,.09.28,1.36,1.36,0,0,1,0,.29,1.39,1.39,0,0,1-.12.57,1.43,1.43,0,0,1-.32.49A1.52,1.52,0,0,1,320,392.83Z"
          style="fill:#fff"></path>
      <path
          d="M318.48,354.49A1.5,1.5,0,0,1,320,353h0a1.5,1.5,0,0,1,1.5,1.5h0A1.5,1.5,0,0,1,320,356h0A1.5,1.5,0,0,1,318.48,354.49Zm0-36.83a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,318.48,317.66Zm0-36.84a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,318.48,280.82Zm0-36.84a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,318.48,244Zm0-36.83a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,318.48,207.15Z"
          style="fill:#fff"></path>
      <path
          d="M320,171.81a1.48,1.48,0,0,1-1.06-.44,1.06,1.06,0,0,1-.19-.23,2,2,0,0,1-.14-.25,1,1,0,0,1-.08-.29,1.36,1.36,0,0,1,0-.29,1.52,1.52,0,0,1,.44-1.06,1.37,1.37,0,0,1,.22-.19,1.26,1.26,0,0,1,.26-.13,1.52,1.52,0,0,1,.28-.09,1.47,1.47,0,0,1,.59,0,1.52,1.52,0,0,1,.28.09,1.26,1.26,0,0,1,.26.13,2.11,2.11,0,0,1,.23.19,1.52,1.52,0,0,1,.44,1.06,1.36,1.36,0,0,1,0,.29,1.61,1.61,0,0,1-.09.29,1.23,1.23,0,0,1-.14.25,1,1,0,0,1-.18.23A1.52,1.52,0,0,1,320,171.81Z"
          style="fill:#fff"></path>
      <path
          d="M389.88,170.31a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,389.88,170.31Zm-35.7,0a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,354.18,170.31Z"
          style="fill:#fff"></path>
      <path
          d="M427.08,171.81a1.43,1.43,0,0,1-.29,0l-.28-.08-.26-.14a2.11,2.11,0,0,1-.23-.19,1.5,1.5,0,0,1-.44-1.06,1.52,1.52,0,0,1,.44-1.06,1.3,1.3,0,0,1,.23-.18.94.94,0,0,1,.26-.14,1.31,1.31,0,0,1,.28-.09,1.47,1.47,0,0,1,.59,0l.28.09a.89.89,0,0,1,.25.14,1,1,0,0,1,.23.18,1.52,1.52,0,0,1,.44,1.06,1.5,1.5,0,0,1-.44,1.06,1.45,1.45,0,0,1-.23.19,2,2,0,0,1-.25.14l-.28.08A1.53,1.53,0,0,1,427.08,171.81Z"
          style="fill:#fff"></path>
      <path
          d="M425.58,354.49a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,425.58,354.49Zm0-36.83a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,425.58,317.66Zm0-36.84a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,425.58,280.82Zm0-36.84a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,425.58,244Zm0-36.83a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,425.58,207.15Z"
          style="fill:#fff"></path>
      <path
          d="M427.08,392.83a1.5,1.5,0,0,1-1.06-2.56,2.11,2.11,0,0,1,.23-.19l.26-.14.28-.08a1.47,1.47,0,0,1,.59,0l.28.08a2,2,0,0,1,.25.14,1.45,1.45,0,0,1,.23.19,1.5,1.5,0,0,1,.44,1.06,1.46,1.46,0,0,1-.44,1.06A1.5,1.5,0,0,1,427.08,392.83Z"
          style="fill:#fff"></path>
      <rect x="53.79" y="392.21" width="41.21" height="0.25" style="fill:#e0e0e0"></rect>
      <rect x="137.67" y="390.21" width="41.33" height="0.25" style="fill:#e0e0e0"></rect>
      <rect x="222.78" y="390.21" width="10" height="0.25" style="fill:#e0e0e0"></rect>
      <rect x="86.58" y="398.75" width="46.25" height="0.25" style="fill:#e0e0e0"></rect>
      <rect x="271.2" y="392.08" width="21.6" height="0.25" style="fill:#e0e0e0"></rect>
      <rect x="301.7" y="392.08" width="27.93" height="0.25" style="fill:#e0e0e0"></rect>
      <rect x="401.89" y="396.31" width="62.44" height="0.25" style="fill:#e0e0e0"></rect>
      <polygon points="233.41 87.65 187.34 87.65 189.86 58.94 235.92 58.94 233.41 87.65" style="fill:#ebebeb"></polygon>
      <polygon points="234.34 55.04 233.08 70.03 231.82 88.05 187.52 88.05 190.04 55.04 234.34 55.04"
               style="fill:#fafafa"></polygon>
      <polygon
          points="230.11 58.94 232.62 87.65 186.56 87.65 184.04 58.94 187.96 58.94 189.32 56.78 201.73 56.78 203.51 58.94 230.11 58.94"
          style="fill:#e0e0e0"></polygon>
      <rect x="255.79" y="94.06" width="49.02" height="49.02" style="fill:#ebebeb"></rect>
      <rect x="304.81" y="94.06" width="1.25" height="49.02" style="fill:#e0e0e0"></rect>
      <rect x="260.31" y="98.6" width="39.96" height="39.96" style="fill:#f5f5f5"></rect>
      <rect x="260.32" y="98.6" width="0.37" height="39.96" style="fill:#e0e0e0"></rect>
      <ellipse cx="280.67" cy="114.43" rx="7.79" ry="9.92" style="fill:#e0e0e0"></ellipse>
      <rect x="276.6" y="121.15" width="8.21" height="8.21" transform="translate(561.41 250.52) rotate(180)"
            style="fill:#e0e0e0"></rect>
      <path
          d="M265.4,126.12h30.08a0,0,0,0,1,0,0v2.8a9.64,9.64,0,0,1-9.64,9.64H275a9.64,9.64,0,0,1-9.64-9.64v-2.8A0,0,0,0,1,265.4,126.12Z"
          transform="translate(560.87 264.68) rotate(180)" style="fill:#e0e0e0"></path>
      <path
          d="M264.64,121.88c0,1.63,3,3,3,3s3-1.33,3-3c0-.78-1.08-1.22-1.79-1A2.18,2.18,0,0,0,267.6,122a3.87,3.87,0,0,0-1.47-1.2A1.12,1.12,0,0,0,264.64,121.88Z"
          style="fill:#e0e0e0"></path>
      <path
          d="M291.26,114.59c0,2.15,3.89,3.89,3.89,3.89s3.89-1.74,3.89-3.89c0-1-1.44-1.59-2.35-1.33a2.84,2.84,0,0,0-1.54,1.48,5.26,5.26,0,0,0-1.93-1.58A1.47,1.47,0,0,0,291.26,114.59Z"
          style="fill:#e0e0e0"></path>
      <path
          d="M205.71,359.32c.46,2.89,2.55,5.38,2.71,8.3.13,2.29-.94,4.44-1.47,6.67s-.27,5,1.68,6.18a4.48,4.48,0,0,0,4.52-.27,10.26,10.26,0,0,0,3.15-3.49,32.46,32.46,0,0,0,4.85-16.48c0-2.45-.25-5.05-1.69-7C215,347,204.57,352.12,205.71,359.32Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M202,351.67a3.2,3.2,0,0,0,1.61.95,2.32,2.32,0,0,0,2.21-.43,2.61,2.61,0,0,0,.5-2.11,5,5,0,0,0-.91-2.56,2.78,2.78,0,0,0-2.36-1.15,2,2,0,0,0-1.82,1.74A6.11,6.11,0,0,0,202,351.67Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M210.31,345.66a1.92,1.92,0,0,0-1.94-.85,1.87,1.87,0,0,0-1.44,1.54,5.54,5.54,0,0,0,0,.73,5.82,5.82,0,0,0,.13,1.17,2.27,2.27,0,0,0,.51,1,2.39,2.39,0,0,0,1.25.64,1.86,1.86,0,0,0,1.67-.42,2.35,2.35,0,0,0,.52-1.75A3.72,3.72,0,0,0,210.31,345.66Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M214.94,345.41a1.43,1.43,0,0,0-1.75-.66,2.08,2.08,0,0,0-1.24,1.51c0,.22-.06.44-.07.66A4.28,4.28,0,0,0,212,348a1.65,1.65,0,0,0,.52.9,1.84,1.84,0,0,0,1,.42,1.66,1.66,0,0,0,1.33-.43,2.41,2.41,0,0,0,.6-1.6A3,3,0,0,0,214.94,345.41Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M219.07,346.37c-.49-.73-1-.78-1.56-.47a2.63,2.63,0,0,0-1,1.49,3.64,3.64,0,0,0-.12.59,3.1,3.1,0,0,0,0,1,1.27,1.27,0,0,0,.52.79,1.35,1.35,0,0,0,.83.2,1.61,1.61,0,0,0,1-.47,2.57,2.57,0,0,0,.67-1.43A2.41,2.41,0,0,0,219.07,346.37Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M219.88,350.42a1.6,1.6,0,0,0,0,.72.75.75,0,0,0,.43.56,1.06,1.06,0,0,0,1.1-.42,2.57,2.57,0,0,0,.63-1.08,1.57,1.57,0,0,0-.15-1.22C221,347.53,220,349.7,219.88,350.42Z"
          style="fill:#f5f5f5"></path>
      <path d="M220.69,426.11c.67-2.36,1.06-4.95.17-7.24-2.75-7.13-14.11-4.86-14.86,2.4Z" style="fill:#f5f5f5"></path>
      <path
          d="M204.42,412.93a3.19,3.19,0,0,0,1.32,1.33,2.31,2.31,0,0,0,2.24.15,2.63,2.63,0,0,0,1-1.91,5,5,0,0,0-.22-2.7,2.81,2.81,0,0,0-2-1.73,2,2,0,0,0-2.2,1.21A6.17,6.17,0,0,0,204.42,412.93Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M214,409.24a1.94,1.94,0,0,0-1.66-1.32,1.89,1.89,0,0,0-1.79,1.12,5.78,5.78,0,0,0-.22.7,5.51,5.51,0,0,0-.18,1.16,2.2,2.2,0,0,0,.23,1.13,2.42,2.42,0,0,0,1,.93,1.82,1.82,0,0,0,1.71,0,2.36,2.36,0,0,0,1-1.55A3.84,3.84,0,0,0,214,409.24Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M218.5,410.19A1.44,1.44,0,0,0,217,409.1a2.11,2.11,0,0,0-1.59,1.15,6.44,6.44,0,0,0-.24.61,4.07,4.07,0,0,0-.2,1.05,1.69,1.69,0,0,0,.26,1,1.81,1.81,0,0,0,.9.67,1.64,1.64,0,0,0,1.4-.08,2.4,2.4,0,0,0,1-1.38A3,3,0,0,0,218.5,410.19Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M222.24,412.18c-.28-.84-.81-1-1.38-.86a2.66,2.66,0,0,0-1.38,1.17,5.42,5.42,0,0,0-.27.54,3.07,3.07,0,0,0-.22.94,1.28,1.28,0,0,0,.29.89,1.33,1.33,0,0,0,.75.41,1.55,1.55,0,0,0,1.09-.19,2.58,2.58,0,0,0,1-1.21A2.4,2.4,0,0,0,222.24,412.18Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M222,416.3a1.58,1.58,0,0,0-.21.69.77.77,0,0,0,.27.65,1.06,1.06,0,0,0,1.17-.12,2.68,2.68,0,0,0,.89-.88,1.59,1.59,0,0,0,.17-1.22C223.77,413.79,222.32,415.65,222,416.3Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M181.27,383.66c.37,2.91-1,5.88-.3,8.73.51,2.23,2.14,4,3.26,6s1.65,4.72.09,6.4a4.46,4.46,0,0,1-4.41,1,10.49,10.49,0,0,1-4-2.49,32.45,32.45,0,0,1-9.22-14.49c-.72-2.35-1.16-4.92-.32-7.23C169,374.39,180.38,376.43,181.27,383.66Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M182.68,375.3a3.15,3.15,0,0,1-1.28,1.35,2.29,2.29,0,0,1-2.24.2,2.59,2.59,0,0,1-1.07-1.89,5.09,5.09,0,0,1,.16-2.71,2.81,2.81,0,0,1,2-1.76,2,2,0,0,1,2.23,1.16C182.76,372.58,183.11,374.39,182.68,375.3Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M173.07,371.81a1.94,1.94,0,0,1,1.63-1.36,1.89,1.89,0,0,1,1.81,1.09,5,5,0,0,1,.24.69,5.42,5.42,0,0,1,.2,1.15,2.14,2.14,0,0,1-.21,1.14,2.34,2.34,0,0,1-1,1,1.82,1.82,0,0,1-1.71.07,2.36,2.36,0,0,1-1-1.54A3.72,3.72,0,0,1,173.07,371.81Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M168.55,372.85a1.43,1.43,0,0,1,1.5-1.12,2.11,2.11,0,0,1,1.61,1.11,5.22,5.22,0,0,1,.25.61,4.1,4.1,0,0,1,.23,1.05,1.73,1.73,0,0,1-.25,1,1.81,1.81,0,0,1-.88.69,1.63,1.63,0,0,1-1.4-.05,2.37,2.37,0,0,1-1-1.36A3,3,0,0,1,168.55,372.85Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M164.85,374.92c.27-.84.79-1,1.37-.89a2.66,2.66,0,0,1,1.4,1.15,4.6,4.6,0,0,1,.28.53,2.72,2.72,0,0,1,.24.93,1.24,1.24,0,0,1-.27.9,1.32,1.32,0,0,1-.74.42,1.57,1.57,0,0,1-1.1-.16,2.63,2.63,0,0,1-1.05-1.19A2.41,2.41,0,0,1,164.85,374.92Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M165.19,379a1.76,1.76,0,0,1,.23.69.79.79,0,0,1-.26.66,1.09,1.09,0,0,1-1.18-.1,2.56,2.56,0,0,1-.9-.86,1.55,1.55,0,0,1-.19-1.22C163.36,376.56,164.85,378.39,165.19,379Z"
          style="fill:#f5f5f5"></path>
      <path
          d="M156.73,143.59a11.75,11.75,0,1,1,10-13.27A11.77,11.77,0,0,1,156.73,143.59Zm-2.38-17.1a5.51,5.51,0,1,0,6.21,4.7A5.52,5.52,0,0,0,154.35,126.49Z"
          style="fill:#e0e0e0"></path>
      <rect x="149.86" y="97.09" width="4.46" height="28.69" transform="translate(-13.94 22.09) rotate(-7.95)"
            style="fill:#e0e0e0"></rect>
      <rect x="147.94" y="93.82" width="8.37" height="4.46" transform="matrix(0.99, -0.14, 0.14, 0.99, -11.82, 21.95)"
            style="fill:#e0e0e0"></rect>
      <rect x="149.23" y="99.46" width="7.87" height="4.46" transform="translate(-12.59 22.15) rotate(-7.94)"
            style="fill:#e0e0e0"></rect>
      <rect x="151.36" y="107.4" width="6.87" height="4.46" transform="translate(-13.67 22.45) rotate(-7.94)"
            style="fill:#e0e0e0"></rect>
    </g>
    <g id="freepik--Shadows--inject-3">
      <g id="freepik--Shadow--inject-214--inject-3">
        <ellipse id="freepik--path--inject-214--inject-3" cx="250" cy="431.27" rx="193.89" ry="11.32"
                 style="fill:#f5f5f5"></ellipse>
      </g>
    </g>
    <g id="freepik--Fingerprint--inject-3">
      <ellipse cx="128.44" cy="225.06" rx="24.14" ry="26.34" transform="translate(-122.95 162.21) rotate(-46.25)"
               style="fill:#20AB9C"></ellipse>
      <g style="opacity:0.8">
        <ellipse cx="128.44" cy="225.06" rx="24.14" ry="26.34" transform="translate(-122.95 162.21) rotate(-46.25)"
                 style="fill:#fff"></ellipse>
      </g>
      <path
          d="M116.69,209.24a1,1,0,0,1-.6-1.8,19.21,19.21,0,0,1,11.57-3.6h0a1,1,0,0,1,0,2h0a17.18,17.18,0,0,0-10.37,3.2A1,1,0,0,1,116.69,209.24Z"
          style="fill:#20AB9C"></path>
      <path
          d="M121.42,243.64a1,1,0,0,1-.47-.12,23.3,23.3,0,0,1-11.9-16.18,20.37,20.37,0,0,1,4-17,1,1,0,0,1,1.54,1.28A18.39,18.39,0,0,0,111,227a21.37,21.37,0,0,0,10.88,14.8,1,1,0,0,1-.47,1.88Z"
          style="fill:#20AB9C"></path>
      <path
          d="M131.43,246.25a21.6,21.6,0,0,1-6.59-1.06,1,1,0,0,1,.62-1.91c6.15,2,12.29.88,16.84-3a17.92,17.92,0,0,0,5.78-16.6,1,1,0,1,1,2-.32,19.83,19.83,0,0,1-6.45,18.43A18.44,18.44,0,0,1,131.43,246.25Z"
          style="fill:#20AB9C"></path>
      <path
          d="M148.25,221.12a1,1,0,0,1-.95-.7,21.31,21.31,0,0,0-11.58-12.89,1,1,0,0,1,.8-1.84,23.34,23.34,0,0,1,12.68,14.12,1,1,0,0,1-.64,1.26A1.18,1.18,0,0,1,148.25,221.12Z"
          style="fill:#20AB9C"></path>
      <path
          d="M132.49,206.4a.85.85,0,0,1-.23,0,20.33,20.33,0,0,0-4.59-.53,1,1,0,0,1-1-1,1,1,0,0,1,1-1h0a22.05,22.05,0,0,1,5,.59,1,1,0,0,1-.23,2Z"
          style="fill:#20AB9C"></path>
      <path
          d="M114.85,230.29a1,1,0,0,1-.94-.65,15.87,15.87,0,0,1,1.76-14.78,15,15,0,0,1,12.52-6.46h0a1,1,0,0,1,0,2h0A13.09,13.09,0,0,0,117.3,216,13.93,13.93,0,0,0,115.79,229a1,1,0,0,1-.59,1.28A1.07,1.07,0,0,1,114.85,230.29Z"
          style="fill:#20AB9C"></path>
      <path
          d="M130.71,241.71h0a18.63,18.63,0,0,1-15-8.47,1,1,0,0,1,1.67-1.09,16.62,16.62,0,0,0,13.33,7.56,1,1,0,0,1,0,2Z"
          style="fill:#20AB9C"></path>
      <path
          d="M134.45,241.28a1,1,0,0,1-1-.76,1,1,0,0,1,.73-1.21c8.11-2,10.21-9.11,9.54-14.57-.87-7.12-6.49-14.33-15.56-14.34a1,1,0,0,1-1-1,1,1,0,0,1,1-1h0c10.23,0,16.57,8.1,17.55,16.1,1,8.25-3.33,14.83-11.05,16.75A1,1,0,0,1,134.45,241.28Z"
          style="fill:#20AB9C"></path>
      <path
          d="M124.1,215.92a1,1,0,0,1-.9-.58,1,1,0,0,1,.48-1.33,11.71,11.71,0,0,1,4.87-1,1,1,0,0,1,0,2,9.66,9.66,0,0,0-4,.87A1,1,0,0,1,124.1,215.92Z"
          style="fill:#20AB9C"></path>
      <path
          d="M130.53,237.05a13.25,13.25,0,0,1-11.91-7.9c-2.07-4.51-1.46-9.22,1.63-12.62a1,1,0,0,1,1.41-.06,1,1,0,0,1,.06,1.41,9.28,9.28,0,0,0-1.28,10.43,11.25,11.25,0,0,0,10.41,6.74,8.64,8.64,0,0,0,7.85-5.17A10.33,10.33,0,0,0,136.86,219a1,1,0,1,1,1.52-1.3,12.32,12.32,0,0,1,2.15,13,10.62,10.62,0,0,1-9.62,6.36Z"
          style="fill:#20AB9C"></path>
      <path
          d="M134.74,216.8a.94.94,0,0,1-.53-.16,10.56,10.56,0,0,0-5.66-1.68,1,1,0,0,1,0-2h0a12.48,12.48,0,0,1,6.73,2,1,1,0,0,1-.54,1.84Z"
          style="fill:#20AB9C"></path>
      <path
          d="M130,232.54a8,8,0,0,1-6.11-2.9,7.61,7.61,0,0,1-1.2-8.15,6.75,6.75,0,0,1,6.27-4h0a1,1,0,0,1,0,2,4.79,4.79,0,0,0-4.45,2.81,5.61,5.61,0,0,0,.91,6,5.91,5.91,0,0,0,6,2,4.73,4.73,0,0,0,3.59-3.64,1,1,0,0,1,1.19-.76,1,1,0,0,1,.77,1.19,6.71,6.71,0,0,1-5.06,5.15A8.12,8.12,0,0,1,130,232.54Z"
          style="fill:#20AB9C"></path>
      <path
          d="M135.82,224.62a1,1,0,0,1-1-.7,6.32,6.32,0,0,0-5.87-4.4,1,1,0,1,1,0-2h0a8.37,8.37,0,0,1,7.78,5.8,1,1,0,0,1-.65,1.26A1,1,0,0,1,135.82,224.62Z"
          style="fill:#20AB9C"></path>
      <path
          d="M130.27,228.38a1,1,0,0,1,0-2,1,1,0,0,0,1-1.06,1.47,1.47,0,0,0-1.45-1.51,1.11,1.11,0,0,0-.78.35,1.2,1.2,0,0,0-.28.88,1,1,0,1,1-2,.12,3.09,3.09,0,0,1,3-3.35,3.46,3.46,0,0,1,3.45,3.51,3,3,0,0,1-3,3.06Z"
          style="fill:#20AB9C"></path>
      <path
          d="M156,225.05c1.22,13.93-9.09,25.22-23,25.22l-1.08,0c13.26-.68,22.89-11.7,21.71-25.19a28.13,28.13,0,0,0-26.11-25.18c.35,0,.71,0,1.07,0C142.54,199.84,154.82,211.13,156,225.05Z"
          style="fill:#20AB9C"></path>
      <g style="opacity:0.4;isolation:isolate">
        <path
            d="M156,225.05c1.22,13.93-9.09,25.22-23,25.22l-1.08,0c13.26-.68,22.89-11.7,21.71-25.19a28.13,28.13,0,0,0-26.11-25.18c.35,0,.71,0,1.07,0C142.54,199.84,154.82,211.13,156,225.05Z"
            style="fill:#fff"></path>
      </g>
    </g>
    <g id="freepik--verification-code--inject-3">
      <path
          d="M176.42,102.14a8.66,8.66,0,0,0,.12.89,9.12,9.12,0,0,0,2.82,5,9,9,0,0,0,6.07,2.43h84.49a7.44,7.44,0,0,0,7.59-7.4,8.88,8.88,0,0,0,0-.89,9.25,9.25,0,0,0-9-8.28H184A7.47,7.47,0,0,0,176.42,102.14Z"
          style="fill:#20AB9C"></path>
      <path
          d="M176.42,102.14a8.66,8.66,0,0,0,.12.89,9.12,9.12,0,0,0,2.82,5,9,9,0,0,0,6.07,2.43h84.49a7.44,7.44,0,0,0,7.59-7.4,8.88,8.88,0,0,0,0-.89,9.25,9.25,0,0,0-9-8.28H184A7.47,7.47,0,0,0,176.42,102.14Z"
          style="fill:#fff;opacity:0.8"></path>
      <path
          d="M277.51,103a5.29,5.29,0,0,1-.06.8,9.25,9.25,0,0,0-9-8L178.62,96A7.56,7.56,0,0,1,184,93.86h84.49a9.25,9.25,0,0,1,9,8.28A8.88,8.88,0,0,1,277.51,103Z"
          style="fill:#20AB9C"></path>
      <path
          d="M277.51,103a5.29,5.29,0,0,1-.06.8,9.25,9.25,0,0,0-9-8L178.62,96A7.56,7.56,0,0,1,184,93.86h84.49a9.25,9.25,0,0,1,9,8.28A8.88,8.88,0,0,1,277.51,103Z"
          style="fill:#fff;opacity:0.5;isolation:isolate"></path>
      <path
          d="M263,97.86l.36,4,3.76-1.4.89,2-3.84,1.28,2.76,3.39-1.73,1.27-2.69-3.52-2,3.52-1.89-1.27,2.13-3.39-4-1.28.54-2,4.05,1.35-.36-4Z"
          style="fill:#20AB9C"></path>
      <path
          d="M239.85,97.86l.36,4,3.76-1.4.9,2L241,103.77l2.76,3.39-1.72,1.27-2.69-3.52-2,3.52-1.9-1.27,2.14-3.39-4-1.28.54-2,4.05,1.35-.35-4Z"
          style="fill:#20AB9C"></path>
      <path
          d="M216.75,97.86l.35,4,3.76-1.4.9,2-3.85,1.28,2.76,3.39L219,108.43l-2.69-3.52-2,3.52-1.89-1.27,2.13-3.39-4-1.28.55-2,4,1.35-.35-4Z"
          style="fill:#20AB9C"></path>
      <path
          d="M193.64,97.86l.35,4,3.77-1.4.89,2-3.85,1.28,2.77,3.39-1.73,1.27-2.69-3.52-2,3.52-1.89-1.27,2.13-3.39-4-1.28.54-2,4,1.35-.35-4Z"
          style="fill:#20AB9C"></path>
      <rect x="245.71" y="89.94" width="3.94" height="1" style="fill:#20AB9C"></rect>
      <rect x="253.73" y="89.94" width="12.85" height="1" style="fill:#20AB9C"></rect>
    </g>
    <g id="freepik--face-recognition--inject-3">
      <path
          d="M397.79,127.24,393.7,174a6.17,6.17,0,0,1-6,5.52H355.23a5,5,0,0,1-5-5.52l4.09-46.77a6.14,6.14,0,0,1,6-5.52h32.47A5,5,0,0,1,397.79,127.24Z"
          style="fill:#20AB9C;opacity:0.8"></path>
      <path
          d="M397.79,127.24,393.7,174a6.17,6.17,0,0,1-6,5.52H355.23a5,5,0,0,1-5-5.52l4.09-46.77a6.14,6.14,0,0,1,6-5.52h32.47A5,5,0,0,1,397.79,127.24Z"
          style="fill:#fff;opacity:0.8"></path>
      <path
          d="M357.28,134.78h-.09a1,1,0,0,1-.91-1.08l.38-4.28a1,1,0,0,1,1-.91h4.52a1,1,0,0,1,0,2h-3.6l-.3,3.36A1,1,0,0,1,357.28,134.78Z"
          style="fill:#20AB9C"></path>
      <path
          d="M393.64,134.78h-.09a1,1,0,0,1-.91-1.08l.28-3.19H389.5a1,1,0,0,1,0-2H394a1,1,0,0,1,1,1.09l-.38,4.27A1,1,0,0,1,393.64,134.78Z"
          style="fill:#20AB9C"></path>
      <path
          d="M359,167.05h-4.52a1,1,0,0,1-.73-.32,1,1,0,0,1-.26-.76l.37-4.28a1,1,0,0,1,2,.17l-.28,3.19H359a1,1,0,0,1,0,2Z"
          style="fill:#20AB9C"></path>
      <path
          d="M390.81,167.05H386.3a1,1,0,0,1,0-2h3.6l.29-3.36a1,1,0,0,1,1.08-.91,1,1,0,0,1,.91,1.08l-.37,4.28A1,1,0,0,1,390.81,167.05Z"
          style="fill:#20AB9C"></path>
      <ellipse cx="374.61" cy="147.63" rx="16.37" ry="13.16" transform="translate(138.13 473.52) rotate(-75.54)"
               style="fill:#fff"></ellipse>
      <polygon points="378.55 172.53 365.6 172.53 366.73 159.58 379.68 159.58 378.55 172.53"
               style="fill:#fff"></polygon>
      <path d="M393.7,174a6.17,6.17,0,0,1-6,5.52H355.23a5,5,0,0,1-5-5.52s14.92-4.72,16.76-4.72h11.53Z"
            style="fill:#fff"></path>
      <rect x="390.17" y="149.62" width="18.43" height="1" transform="translate(217.01 535.84) rotate(-85.29)"
            style="fill:#20AB9C"></rect>
      <rect x="399.24" y="133.84" width="3.62" height="1" transform="translate(234.26 523.01) rotate(-85.29)"
            style="fill:#20AB9C"></rect>
    </g>
    <g id="freepik--Verified--inject-3">
      <rect x="361.94" y="244.07" width="3.94" height="1" style="fill:#20AB9C"></rect>
      <rect x="369.96" y="244.07" width="12.85" height="1" style="fill:#20AB9C"></rect>
      <path
          d="M406.33,227l-2.49,28.5a5.18,5.18,0,0,1-5.06,4.65,4.2,4.2,0,0,1-4.25-4.65L397,227a15.08,15.08,0,0,0-15.24-16.7H371.6A18.61,18.61,0,0,0,353.44,227l-1,11.64h-9.3l1-11.64c1.25-14.34,13.94-26,28.27-26h10.2A23.47,23.47,0,0,1,406.33,227Z"
          style="fill:#20AB9C"></path>
      <g style="opacity:0.5;isolation:isolate">
        <path
            d="M406.33,227l-2.49,28.5a5.18,5.18,0,0,1-5.06,4.65,4.2,4.2,0,0,1-4.25-4.65L397,227a15.08,15.08,0,0,0-15.24-16.7H371.6A18.61,18.61,0,0,0,353.44,227l-1,11.64h-9.3l1-11.64c1.25-14.34,13.94-26,28.27-26h10.2A23.47,23.47,0,0,1,406.33,227Z"
            style="fill:#fff"></path>
      </g>
      <path
          d="M407,254.46,402.15,310a7.07,7.07,0,0,1-6.92,6.35H339.69a5.73,5.73,0,0,1-5.8-6.35l4.86-55.54a7.07,7.07,0,0,1,3.46-5.39,6.8,6.8,0,0,1,3.46-1H401.2a6,6,0,0,1,3.21.91A5.76,5.76,0,0,1,407,254.46Z"
          style="fill:#20AB9C"></path>
      <path d="M349.25,278.72l14.43,22.21,47.59-47.59a.48.48,0,0,0-.63-.71l-45.35,35.55-8.57-13.81Z"
            style="fill:#fff"></path>
    </g>
    <g id="freepik--Character--inject-3">
      <path
          d="M276,191.65l.34.41.46.53c.33.35.65.72,1,1.06.69.72,1.44,1.42,2.2,2.13a58.1,58.1,0,0,0,4.77,3.95c1.69,1.26,3.47,2.41,5.25,3.55,1,.54,1.82,1.11,2.76,1.63l2.17,1.2a1.44,1.44,0,0,0,1,.06c.7-.21,4.72-4.19,6-5.67,2.84-3.25,7.14-7.53,9.46-11.17L314,192.9a58.69,58.69,0,0,1-6.47,12.64,28,28,0,0,1-5.1,5.71,14.34,14.34,0,0,1-3.92,2.35,9.51,9.51,0,0,1-5.68.43,8.9,8.9,0,0,1-1.43-.48l-.9-.39-1.62-.74c-1.09-.48-2.14-1.06-3.2-1.59a66.35,66.35,0,0,1-6.16-3.73,63.54,63.54,0,0,1-5.75-4.46c-.95-.83-1.8-1.67-2.67-2.58-.43-.45-.87-.92-1.29-1.41l-.62-.76c-.23-.28-.4-.49-.71-.92Z"
          style="fill:#ffb573"></path>
      <path d="M311,189.88l4.64-9.92,4.43,7.94s-2.91,5-6.07,5Z" style="fill:#ffb573"></path>
      <polygon points="320.9 175.84 325.58 181.86 320.11 187.89 315.68 179.96 320.9 175.84"
               style="fill:#ffb573"></polygon>
      <path
          d="M319.27,190.63h7.37a.84.84,0,0,0,.83-.76L329,172.2a.83.83,0,0,0-.83-.91h-7.36a.84.84,0,0,0-.83.77l-1.55,17.66A.84.84,0,0,0,319.27,190.63Z"
          style="fill:#20AB9C"></path>
      <polygon points="319.35 188.86 326.73 188.86 328.11 173.07 320.73 173.07 319.35 188.86"
               style="fill:#fff;opacity:0.30000000000000004"></polygon>
      <path
          d="M318.87,186.62h0l1.31-.67a8,8,0,0,0,1.83-1.28l.24-.87a1.26,1.26,0,0,0-1.39-.57l-1.09.3-5.41.41-.13,2.28,1.58,2.44.54,0A2.47,2.47,0,0,0,318.87,186.62Z"
          style="fill:#ffb573"></path>
      <path
          d="M266.56,182c3.75-.68,30.2,22.25,30.2,22.25l-7.84,11.5s-28.92-20.14-28-25C261.81,185.64,261.1,183,266.56,182Z"
          style="fill:#263238"></path>
      <path d="M269.9,189.33l-.29,12c3,2.33,13.84,10.64,13.84,10.64Z" style="opacity:0.2;isolation:isolate"></path>
      <polygon points="248.93 420.31 257.8 421.18 260.62 400.61 251.74 399.74 248.93 420.31"
               style="fill:#ffb573"></polygon>
      <polygon points="161.6 299.61 159.33 308.25 178.52 316.52 182.5 307.75 161.6 299.61"
               style="fill:#ffb573"></polygon>
      <path
          d="M264.15,423a3.38,3.38,0,0,0,2.19-.48,1.18,1.18,0,0,0,.4-1,.65.65,0,0,0-.34-.57c-1.07-.64-4.69,1.08-5.1,1.27a.21.21,0,0,0-.12.21.21.21,0,0,0,.17.18A15.5,15.5,0,0,0,264.15,423Zm1.61-1.79a.7.7,0,0,1,.4.09.26.26,0,0,1,.14.23.8.8,0,0,1-.27.67c-.55.47-2,.51-4,.13a11.52,11.52,0,0,1,3.76-1.13Z"
          style="fill:#20AB9C"></path>
      <path
          d="M261.39,422.57l.08,0c1.08-.45,3.25-2.31,3.11-3.33a.79.79,0,0,0-.78-.62,1.47,1.47,0,0,0-1.19.3c-1.15.89-1.42,3.3-1.44,3.39a.23.23,0,0,0,.05.21A.22.22,0,0,0,261.39,422.57ZM263.6,419l.12,0c.39.06.41.21.41.26.09.61-1.39,2.09-2.5,2.68a4.78,4.78,0,0,1,1.22-2.72,1.06,1.06,0,0,1,.77-.19Z"
          style="fill:#20AB9C"></path>
      <path
          d="M257.36,417.6l-8,.34a.7.7,0,0,0-.69.63l-1.92,11.17a1.44,1.44,0,0,0,1.37,1.52h.16c3.49-.18,5.17-.44,9.59-.59,2.71-.09,11,.89,14.7.05s3-4.51,1.41-4.48c-5.14.1-11.07-3.25-14-7.17C259.47,418.74,257.92,417.57,257.36,417.6Z"
          style="fill:#263238"></path>
      <path
          d="M163.41,309.38l4.08-6.93a.69.69,0,0,0-.17-.92L159,293.86a1.45,1.45,0,0,0-2,.32l-.09.14c-1.74,3-2.43,4.58-4.7,8.37-1.4,2.33-6.74,8.8-8,12.33s2.16,5,3,3.62c2.71-4.38,8.74-7.54,13.64-7.92C161.32,310.54,163.13,309.87,163.41,309.38Z"
          style="fill:#263238"></path>
      <polygon points="260.62 400.61 259.17 411.22 250.29 410.35 251.73 399.75 260.62 400.61"
               style="opacity:0.2"></polygon>
      <polygon points="188.4 309.89 184.3 319.14 171.94 313.68 175.09 304.87 188.4 309.89"
               style="opacity:0.2"></polygon>
      <path
          d="M272.23,192.55c5.07,8.62-1.13,15.89-6.31,41.65l-33.42.09c.54-13.88.48-25.1-5.44-52.41a4.58,4.58,0,0,1,4.49-5.6c2.76,0,6.23.09,9.76.45a121.54,121.54,0,0,1,16.55,2.51,68.75,68.75,0,0,1,8.7,2.72C268.28,182.54,270.35,187.22,272.23,192.55Z"
          style="fill:#263238"></path>
      <path
          d="M267.16,235.94s18.77,54.27,18.32,80.37c-.26,14.95-1.39,42.81-5.9,62.4-3.68,16-18.68,26.2-18.68,26.2l-12-.71s-6.5-24.4-5.37-34.11c2.25-19.33,8.92-33.85,8.11-51.6-1.28-29.06-9.88-83-9.88-83Z"
          style="fill:#20AB9C"></path>
      <polygon points="246.49 407.33 263.44 408.33 264.98 403.24 246.04 401.49 246.49 407.33"
               style="fill:#263238"></polygon>
      <path d="M248.63,345.58s0-.1.1-.09c10.65,1,18-14.33,16.25-35.32l-5.65-53.5Z"
            style="opacity:0.2;isolation:isolate"></path>
      <path
          d="M258.74,235.49s14.92,95.43-16.1,106.17c-16.37,6.2-30.14-.52-46.45-7.74-9.43-4.17-21.21-15.46-21.21-15.46l4.53-12.33a59,59,0,0,1,25-1.26c15.9,2.46,16.57,7,22.22,3.57,11.88-29.36-8.56-52.57,5.33-73Z"
          style="fill:#20AB9C"></path>
      <polygon points="180.4 303.79 174.34 319.78 178.8 323.19 186 304.16 180.4 303.79" style="fill:#263238"></polygon>
      <path d="M177.9,217.56c-1.51,2.23-9.17.72-9.17.72l-1.63-9.49,9.41,3.77Z" style="fill:#ffb573"></path>
      <path d="M169,208.3l1.46,9.86-5.65.84a1.24,1.24,0,0,1-1.33-1L161.31,211a.65.65,0,0,1,.46-.77Z"
            style="fill:#ffb573"></path>
      <path
          d="M166.47,212.77l3.22.57,3.05-2.29-1.18-.84a4,4,0,0,1-1.58-2.37,17.34,17.34,0,0,1-.41-3.05v-.34a1.39,1.39,0,0,0-1.18-1.2l-.8.59c-.5,1.09-.14,2.36-.2,3.21l-.13,1.65-1.29.56Z"
          style="fill:#ffb573"></path>
      <path d="M228.67,180l-18.92,26L175,212.86v5.7s32.66,2.42,40-1.85,19.65-30.17,19.65-30.17Z"
            style="fill:#ffb573"></path>
      <path
          d="M228.45,130c-7.94-2.66-17.15,2.27-20.78,9.81s-2.36,16.79,1.73,24.09c1.23,2.21,2.7,4.29,3.71,6.6a10.44,10.44,0,0,1,.69,7.37c-.82,2.39-1.66,3.92-4.62,3.54,0,0,0-1.64,1.13-1.44h0s-2.4,1.33-3.34.46c1.09,1.68,4,1.07,4.93-.71,1.39-2.65-1.17-6.24-4.17-6.21s-5.44,3.07-5.34,6.06a8.22,8.22,0,0,0,5,6.89,3.72,3.72,0,0,1-4-2.15c1.36,3.56,5.8,5.13,9.55,4.43s6.92-3.1,9.86-5.53c4-3.28,8.09-7.43,8-12.56-.09-3.83-2.55-7.12-4.62-10.35s-3.88-7.17-2.56-10.76c1.11-3.05,4.13-4.89,6.79-6.75,1.92-1.33,5.82-4.3,5.36-7.11C235.3,133,230.7,130.75,228.45,130Z"
          style="fill:#263238"></path>
      <path
          d="M210.56,219l-5.24-13.87,1.2.1a5.07,5.07,0,0,0,4.85-2.58c4-7.12,14.33-25.19,17.24-26.09,4.15-1.23,8.42,2.09,10,7,1.19,3.61-11,19.09-16.62,27.89C217,219.24,210.56,219,210.56,219Z"
          style="fill:#263238"></path>
      <path
          d="M159.45,313.94v.11a1.22,1.22,0,0,0,.9.76.74.74,0,0,0,.71-.15c.84-.77.3-4,.25-4.32a.2.2,0,0,0-.16-.17.24.24,0,0,0-.21.08C160.26,311.21,159.18,312.92,159.45,313.94Zm1.54-3c.18,1.28.26,3-.21,3.42a.33.33,0,0,1-.32,0,.8.8,0,0,1-.6-.5c-.18-.54.2-1.64,1.13-2.94Z"
          style="fill:#20AB9C"></path>
      <path
          d="M161,310.76c.26,1.08,1.28,2.92,2.18,3.14a.74.74,0,0,0,.82-.42,1.11,1.11,0,0,0,.06-.94c-.41-1.24-2.77-2.26-2.87-2.31a.22.22,0,0,0-.21,0,.22.22,0,0,0-.08.2A2.19,2.19,0,0,0,161,310.76Zm2.72,2a.68.68,0,0,1-.09.52c-.13.24-.26.24-.36.22-.61-.12-1.54-1.63-1.84-2.68.77.39,2,1.12,2.26,1.88a.09.09,0,0,0,0,.06Z"
          style="fill:#20AB9C"></path>
      <path
          d="M232.38,232.6l-.93,3.25c-.12.25.17.51.59.5l34.5,0c.33,0,.6-.17.62-.37l.32-3.25c0-.22-.26-.42-.62-.41l-33.9,0A.64.64,0,0,0,232.38,232.6Z"
          style="fill:#20AB9C"></path>
      <path
          d="M232.38,232.6l-.93,3.25c-.12.25.17.51.59.5l34.5,0c.33,0,.6-.17.62-.37l.32-3.25c0-.22-.26-.42-.62-.41l-33.9,0A.64.64,0,0,0,232.38,232.6Z"
          style="fill:#fff;opacity:0.5;isolation:isolate"></path>
      <path
          d="M239,236.65h-1c-.19,0-.35-.1-.33-.22l.45-4.23c0-.12.19-.22.39-.22h1c.19,0,.34.1.33.23l-.45,4.22C239.33,236.55,239.15,236.66,239,236.65Z"
          style="fill:#20AB9C"></path>
      <path
          d="M263.87,236.65h-1c-.18,0-.34-.1-.32-.22l.45-4.22c0-.13.18-.23.37-.23h1c.19,0,.35.1.33.22l-.45,4.22C264.23,236.55,264.07,236.65,263.87,236.65Z"
          style="fill:#20AB9C"></path>
      <path
          d="M252.46,236.63h-1c-.19,0-.35-.1-.33-.22l.45-4.22c0-.13.19-.23.38-.23h1c.19,0,.34.1.33.22l-.46,4.22C252.83,236.53,252.66,236.63,252.46,236.63Z"
          style="fill:#20AB9C"></path>
      <path
          d="M242.87,158.53c1.06,5.45,2.1,15.29-1.6,18.25,0,0,1.48,5.59,11.37,7.08,10.92,1.64,5.19-4.58,5.19-4.58-5.95-2.35-5.8-6.65-4.78-10.61Z"
          style="fill:#ffb573"></path>
      <path
          d="M241.31,176.73A106.07,106.07,0,0,1,257,178.91l7.48,2.23c1.33,2.68-.85,8.19-7.11,9.24,0,0-15.77,2.17-20.42-14.07Z"
          style="fill:#ffb573"></path>
      <path d="M247,162.68l6,6a15.21,15.21,0,0,0-.49,2.84c-2.28-.67-5.94-3.18-6.19-5.58A8.25,8.25,0,0,1,247,162.68Z"
            style="opacity:0.2;isolation:isolate"></path>
      <path
          d="M236.19,151.65c2.68,8.46,3.62,12.1,9.11,15.43,8.18,5,18-.12,17.9-9.2-.08-8.18-4.43-20.64-13.72-22a12.1,12.1,0,0,0-13.29,15.74Z"
          style="fill:#ffb573"></path>
      <path d="M256.38,150.33a19,19,0,0,0,3.41,4,3.12,3.12,0,0,1-2.43,1Z" style="fill:#ed893e"></path>
      <path
          d="M255.43,159.85a5.82,5.82,0,0,0,1-.2.2.2,0,0,0,.15-.24h0a.21.21,0,0,0-.25-.15,5.67,5.67,0,0,1-5-1,.22.22,0,0,0-.31,0,.21.21,0,0,0,0,.3A6,6,0,0,0,255.43,159.85Z"
          style="fill:#263238"></path>
      <path
          d="M233.28,134.76a8.2,8.2,0,0,0-3.74,6.55,3.06,3.06,0,0,0,.23,1.47,1.42,1.42,0,0,0,1.14.85c.76,0,1.32-.68,1.74-1.32l1.94-3a14.91,14.91,0,0,0,2-3.19C237.24,134.15,234.47,134,233.28,134.76Z"
          style="fill:#20AB9C"></path>
      <path
          d="M234.23,136.75c-5.31,6.14-7.69,23.13,7.87,27.87,2.6-2.06-1.6-5.81-3.38-10.55-1.17-3.1,4.76-8.89,7.07-11.27s4.44-5.81,7.7-5.26a4.4,4.4,0,0,0,2.56.07c2.59-1.17,1.33-2.72,0-3.8a13.31,13.31,0,0,0-7-2.58A17.33,17.33,0,0,0,234.23,136.75Z"
          style="fill:#263238"></path>
      <path
          d="M251.5,134.69c6.25,5.21-2,5.34-6.37,8.64-4.19,3.17-1.25,8.36-8.34,10.77-5.79,1.69-7.13.67-8.73-.45a9.12,9.12,0,0,0,7.53-8.19C236.08,141.66,244.36,128.65,251.5,134.69Z"
          style="fill:#263238"></path>
      <path
          d="M235.25,158.76a6.55,6.55,0,0,0,4.16,2.7c2.24.39,3.1-1.66,2.33-3.66-.73-1.8-2.76-4.17-4.93-3.66a2.91,2.91,0,0,0-2.09,3.54A3,3,0,0,0,235.25,158.76Z"
          style="fill:#ffb573"></path>
      <path d="M250.43,135.22c1.21,3.08,4.7,2.67,7.28,5.89,1.59,2,4.71-4.17.46-6.6S248.53,130.4,250.43,135.22Z"
            style="fill:#263238"></path>
      <path
          d="M246.75,148.22a.42.42,0,0,0,.43-.25c.82-1.82,3.28-2.28,3.3-2.28a.44.44,0,0,0,.34-.5.42.42,0,0,0-.49-.33c-.13,0-2.93.59-3.93,2.79a.43.43,0,0,0,.21.56h.14Z"
          style="fill:#263238"></path>
      <path
          d="M259.75,144.54a.4.4,0,0,0,.37-.19.41.41,0,0,0-.11-.58h0a4.2,4.2,0,0,0-3.84-.49.41.41,0,0,0-.22.56.42.42,0,0,0,.55.22h0a3.31,3.31,0,0,1,3,.42A.6.6,0,0,0,259.75,144.54Z"
          style="fill:#263238"></path>
      <path d="M300.86,199.28,295,202.61l-6.09,13.1s5.88,3.74,10.52.71S300.86,199.28,300.86,199.28Z"
            style="fill:#20AB9C"></path>
      <path d="M300.86,199.28,295,202.61l-6.09,13.1s5.88,3.74,10.52.71S300.86,199.28,300.86,199.28Z"
            style="fill:#fff;opacity:0.5;isolation:isolate"></path>
      <path d="M198.39,203.33l6.93,2,6.57,13.59s-5.28,4.89-11,.22C196.61,215.6,198.39,203.33,198.39,203.33Z"
            style="fill:#20AB9C"></path>
      <g style="opacity:0.5;isolation:isolate">
        <path d="M198.39,203.33l6.93,2,6.57,13.59s-5.28,4.89-11,.22C196.61,215.6,198.39,203.33,198.39,203.33Z"
              style="fill:#fff"></path>
      </g>
      <path d="M259.35,147.93c.48-.09,1,.45,1.11,1.19s-.16,1.42-.64,1.5-1-.45-1.11-1.19S258.87,148,259.35,147.93Z"
            style="fill:#263238"></path>
      <path d="M260.83,147.2l-1.69.83C260.16,148.75,260.83,147.2,260.83,147.2Z" style="fill:#263238"></path>
      <path d="M251,149.65c.48-.09,1,.45,1.11,1.19s-.16,1.42-.64,1.5-1-.45-1.11-1.19S250.5,149.74,251,149.65Z"
            style="fill:#263238"></path>
      <path d="M252.46,148.92l-1.69.83C251.79,150.47,252.46,148.92,252.46,148.92Z" style="fill:#263238"></path>
      <path
          d="M208.66,187.91a7.31,7.31,0,0,1-2.75-.52c-3.1-1.27-4.54-4.49-4-8.85a.5.5,0,0,1,.56-.43.49.49,0,0,1,.43.56c-.52,3.87.7,6.71,3.35,7.79a7.7,7.7,0,0,0,8.65-2.66c2.51-3.34,1.69-8.08-2.22-13-10.24-12.85-9.56-19.68-8.11-25.08,2.1-7.84,8.25-16.82,25.65-15.09a.5.5,0,1,1-.1,1c-16.69-1.66-22.59,6.9-24.59,14.36-1.44,5.4-2,11.71,7.93,24.2,6,7.53,3.57,12.46,2.24,14.22A9,9,0,0,1,208.66,187.91Z"
          style="fill:#263238"></path>
    </g>
  </svg>
</template>
  